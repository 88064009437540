import styled from "styled-components";

const Box = styled.div`
  position: relative;
  z-index: 1;
  display: ${props => props.noColumns ? "" : "grid"};
  grid-template-columns: ${props => props.noColumns ? "" : "1fr 2fr"};
  grid-gap: ${props => props.noColumns ? "" : "40px"};
  margin-top: -150px;
  padding: 64px 64px 64px 64px;
  border: 1px solid #e0dee9;
  background-color: #fff;
  height: 100%;
  max-width: ${props => props.noColumns ? "500px" : ""};
  width: ${props => props.noColumns ? "100%" : ""};
  justify-self: ${props => props.noColumns ? "center" : ""};
  @media screen and (max-width: 800px) {
    padding: 16px;
    margin-top: -50px;
    grid-template-columns: ${props => props.noColumns ? "" : "1fr"};
  }
`

export default Box