import React from 'react';
import {graphql} from "gatsby";

import Layout from "../components/layouts/Layout";
import SpeakerBox from "../components/pages/speaker-template/SpeakerBox";
import EventCard from "../components/cards/EventCard";
import Seo from "../components/main/SEO";

import BackgroundWrapper from "../components/styled-components/wrappers/BackgroundWrapper";
import Col from "../components/styled-components/wrappers/Col";
import SectionLayoutGrid from "../components/styled-components/wrappers/SectionLayoutGrid";
import P from "../components/styled-components/typography/P";
import H2 from "../components/styled-components/typography/H2";
import Divider from "../components/styled-components/divider/Divider";
import moment from "moment";

const SpeakerTemplate = ({data}) => {

    const {avatar, name, description, position, linkedin, blackowl_events, slug} = data.speaker;

    return (
        <Layout title={"Előadói profil"} template>
            <Seo title={name} description={name + " előadő oldala."} keywords={"balasys, blackowl, előadó"} url={"/speakers/" + slug}/>
            <BackgroundWrapper>
                <SectionLayoutGrid alignStart>
                    <Col full marginBottom="small">
                        <SpeakerBox avatar={avatar} position={position} name={name} description={description}
                                    linkedin={linkedin}/>
                    </Col>
                    <Col full center grid>
                        <Col full>
                            <P italic>
                                {name}
                            </P>
                        </Col>
                        <Col full>
                            <H2 medium>
                                Előadások
                            </H2>
                        </Col>
                        <Col full center grid marginBottom="small">
                            <Divider center/>
                        </Col>
                    </Col>
                    {blackowl_events.map((item) => (
                        <Col col4 key={item.id} marginBottom="small">
                            <EventCard
                                speakerName={name}
                                speakerSlug={slug}
                                time={moment(item.date).locale("hu").format("LLL")}
                                speakerAvatar={avatar.url}
                                hero={item.Hero_Image.url}
                                eventSlug={item.slug}
                                eventTitle={item.title}
                            />
                        </Col>
                    ))}
                </SectionLayoutGrid>
            </BackgroundWrapper>
        </Layout>
    );
};

export const query = graphql`
   query GetSingleSpeaker($slug: String) {
         speaker: strapiBlackowlSpeakers(slug: { eq: $slug }) {
            avatar {
              url
            }
            name
            description
            position
            linkedin
            slug
            id
            blackowl_events {
              id
              date
              title
              description
              Hero_Image{
                url
              }
              slug
            }
         }
   }
 `

export default SpeakerTemplate;