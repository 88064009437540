import React from 'react'

import SocialWrapper from "./SocialWrapper"

import CardImg from "../../styled-components/cards/SpeakerCard/CardImg"
import Col from "../../styled-components/wrappers/Col"
import Divider from "../../styled-components/divider/Divider"
import Box from "../../styled-components/speaker-template/Box"
import H2 from "../../styled-components/typography/H2";
import P from "../../styled-components/typography/P";

const SpeakerBox = ({avatar, name, position, linkedin, description}) => {
    return (
        <Box>
            <div>
                <CardImg src={avatar.url} alt={name}/>
            </div>
            <div>
                <Col full>
                    <H2 medium>
                        {name}
                    </H2>
                </Col>
                <Divider/>
                <Col full>
                    <P position>
                        {position}
                    </P>
                </Col>
                <Col full>
                    <SocialWrapper link={linkedin}/>
                </Col>
                <Col full>
                    <H2>
                        Rövid életrajz
                    </H2>
                    <P description>
                        {description}
                    </P>
                </Col>
            </div>
        </Box>
    );
};

export default SpeakerBox;