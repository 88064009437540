import React from 'react'
import styled from "styled-components";

const SpeakerSocial = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 44px;
  padding-top: 16px;
  padding-bottom: 16px;
  align-items: center;
  border-top: 1px solid #e0dee9;
  border-bottom: 1px solid #e0dee9;
`

const A = styled.a`
  display: flex;
  margin-right: 24px;
  padding-top: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  align-items: center;
  opacity: 0.6;
  transition: opacity 200ms ease;
  color: #706b81;
  font-size: 15px;
  line-height: 24px;
  text-decoration: none;
  &:hover{
    opacity: 1;
    text-decoration: underline;
  }
`

const InnerIMG = styled.img`
  width: 16px;
  margin-top: -2px;
  margin-right: 10px;
  opacity: 0.5;
  max-width: 100%;
`

const InnerText = styled.div`
  color: #706b81;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
`

const SocialWrapper = ({link}) => {
    return (
        <SpeakerSocial>
            <A href={link} target="_blank" rel="noreferrer">
                <InnerIMG src="/assets/linkedin-icon.svg" alt="linkedin"/>
                <InnerText>LinkedIn</InnerText>
            </A>
        </SpeakerSocial>
    );
};

export default SocialWrapper;